import { Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ControlledAccordions from "./Accordion";
import { LoadingComponent, ApiLoaderComponent } from "@unity/components";
import StringSecion from "./Sections/StringSection";
import TextBoxSection from "./Sections/TextBoxSection";
import NumberSection from "./Sections/NumberSection";
import OptionsSecion from "./Sections/OptionsSection";
import ImageSecion from "./Sections/ImageSection";
import CheckboxSection from "./Sections/CheckboxSection";
import DocumentSection from "./Sections/DocumentSection";
import SignatureSection from "./Sections/SignatureSection";
import ButtonSection from "./Sections/ButtonSection";
import FixedTextSection from "./Sections/FixedTextSection";

const TemplateEditSection = ({ data, errors, editSelectedRow, toggleRequired, context, editActionRow, selectedRowIndex, editOnChangeAction, editOnchangeSettings, personnel, templateId, editButtonChangeAction, editButtonchangeSettings, mailerId, replaceWhiteSpace }) => {

    const selectionTypes = ["text", "text_box", "number", "currency", "options", "checkbox", "image", "document", "signature", "button", "fixed_text"]
    const [selectedPDF, setSelectedPDF] = useState(false)

    const selectionBlock = (choice) => {
        switch (choice.type) {
            case "text":
                return (<StringSecion data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF} />)
            case "text_box":
                return (<TextBoxSection data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF}/>)
            case "number":
                return (<NumberSection data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF}/>)
            case "currency":
                return (<NumberSection data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF}/>)
            case "options":
                return (<OptionsSecion data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF}/>)
            case "checkbox":
                return (<CheckboxSection 
                    data={data.fields[selectedRowIndex]} 
                    editSelectedRow={editSelectedRow} 
                    errors={errors} 
                    toggleRequired={toggleRequired} 
                    context={context}
                    editActionRow={editActionRow} 
                    editOnChangeAction={editOnChangeAction} 
                    editOnchangeSettings={editOnchangeSettings} 
                    personnel={personnel} 
                    templateId={templateId} 
                    selectedPDF={selectedPDF}
                    mailerId={mailerId}
                    />)
            case "image":
                return (<ImageSecion data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF} />)
            case "document":
                return (<DocumentSection data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF} />)
            case "signature":
                return (<SignatureSection 
                    data={data.fields[selectedRowIndex]} 
                    editSelectedRow={editSelectedRow} 
                    errors={errors} 
                    toggleRequired={toggleRequired} 
                    context={context}
                    editActionRow={editActionRow} 
                    editOnChangeAction={editOnChangeAction} 
                    editOnchangeSettings={editOnchangeSettings} 
                    personnel={personnel} 
                    templateId={templateId} 
                    selectedPDF={selectedPDF}
                    mailerId={mailerId}
                    />)
            case "button":
                return (<ButtonSection 
                    data={data.fields[selectedRowIndex]} 
                    editSelectedRow={editSelectedRow} 
                    errors={errors} 
                    toggleRequired={toggleRequired} 
                    context={context} 
                    editActionRow={editActionRow} 
                    editOnChangeAction={editOnChangeAction} 
                    editOnchangeSettings={editOnchangeSettings} 
                    personnel={personnel} 
                    templateId={templateId} 
                    selectedPDF={selectedPDF}
                    editButtonChangeAction={editButtonChangeAction} 
                    editButtonchangeSettings={editButtonchangeSettings}
                    mailerId={mailerId}
                    />)
            case "fixed_text":
                return (<FixedTextSection data={data.fields[selectedRowIndex]} editSelectedRow={editSelectedRow} errors={errors} toggleRequired={toggleRequired} context={context} selectedPDF={selectedPDF} />)
            default:
                return null
        }
    }



    if (selectedRowIndex != undefined) {
        return (

            <div className="block" style={{ overflowY:"scroll", height: "80vh" }} >
                <div className="container-fluid">
                    <div style={{ width: '100%' }}>

                        <div className="form-row">

                            <div className="form-group col-lg-12">
                                <p><strong>Format</strong></p>
                            </div>

                            <div className="form-group col-lg-12">
                                <TextField
                                    style={{ width: "100%" }}
                                    label="name"
                                    name="name"
                                    value={data.fields[selectedRowIndex].name || ""}
                                    size="small"
                                    onChange={(evt) => editSelectedRow(evt)}
                                    onBlur={(evt) => replaceWhiteSpace(evt)}
                                    helperText="The name must match the name of the component you want to link on the form. No white spaces."
                                />
                            </div>

                            <div className="form-group col-lg-12">
                                <FormControl error={errors && errors.sbu_id || false} fullWidth>
                                    <InputLabel>Format</InputLabel>
                                    <Select
                                        label="type"
                                        name="type"
                                        onChange={(evt) => editSelectedRow(evt)}
                                        //onChange={(evt) => handleChange(evt)}
                                        value={data.fields[selectedRowIndex].type || ""}
                                        //inputProps={{ readOnly: true }}
                                        size="small"
                                    >
                                        {selectionTypes.map((item, key) => {
                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                    {/* <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText> */}
                                </FormControl>
                            </div>

                        </div>

                        <Divider />

                        {data.fields[selectedRowIndex] != null || data.fields[selectedRowIndex] != undefined ?
                            selectionBlock(data.fields[selectedRowIndex])
                            :
                            <p>please select a type</p>

                        }
                    </div>
                </div>
            </div>


        )
    } else {
        return (
            <div className="block" >
                <div className="container-fluid">
                    <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                        <p>Please select a block to start editing</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default TemplateEditSection